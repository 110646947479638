<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant 
              type="search" 
              name="plantCd" 
              v-model="searchParam.plantCd" 
              @datachange="getList"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
            <c-datepicker
              required
              label="목표년도"
              type="year"
              v-model="searchParam.year"
              @datachange="getList"
            />
          </div>
        </template>
      </c-search-box>
    </div>
    <div class="col-6">
      <apexchart 
        ref="strengthFailChart" 
        height="300" 
        :options="strengthFailChart.chartOptions" 
        :series="strengthFailChart.series"></apexchart>
    </div>
    <div class="col-6">
      <apexchart 
        ref="frequencyFailChart" 
        height="300" 
        :options="frequencyFailChart.chartOptions" 
        :series="frequencyFailChart.series"></apexchart>
    </div>
    <div class="col-6">
      <apexchart 
        ref="operationChart" 
        height="300" 
        :options="operationChart.chartOptions" 
        :series="operationChart.series"></apexchart>
    </div>
    <div class="col-6">
      <apexchart 
        ref="pmRateChart" 
        height="300" 
        :options="pmRateChart.chartOptions" 
        :series="pmRateChart.series"></apexchart>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'operaion-rate-kpi',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      searchParam: {
        plantCd: '',
        year: '',
      },
      strengthFailChart: {
        chartOptions: {
          title: {
            text: '고장 강도율(%) [고장시간/부하시간*100]'
          },
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            stacked: false,
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0]
          },
          stroke: {
            width: [0, 4]
          },
          xaxis: {
            categories: [],
          },
          yaxis: [
            {
              min: 0,
              title: {
                text: '전체 강도율',
              },
            }, 
            // {
            //   opposite: true,
            //   title: {
            //     text: '목표 가동율'
            //   }
            // }
          ],
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            },
          },
        },
        series: [
          {
            name: '전체 강도율(%)',
            type: 'column',
            data: []
          },
          {
            name: '목표 강도율(%)',
            type: 'line',
            data: []
          },
        ],
        chartWidth: '80%',
      },
      frequencyFailChart: {
        chartOptions: {
          title: {
            text: '고장 도수율(%) [고장횟수/부하시간*100]'
          },
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            stacked: false,
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0]
          },
          stroke: {
            width: [0, 4]
          },
          xaxis: {
            categories: [],
          },
          yaxis: [
            {
              min: 0,
              range: 0.05,
              title: {
                text: '전체 강도율',
              },
            }, 
            // {
            //   opposite: true,
            //   title: {
            //     text: '목표 가동율'
            //   }
            // }
          ],
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            },
          },
        },
        series: [
          {
            name: '전체 도수율(%) ',
            type: 'column',
            data: []
          },
          {
            name: '목표 도수율(%)',
            type: 'line',
            data: []
          },
        ],
        chartWidth: '80%',
      },
      operationChart: {
        chartOptions: {
          title: {
            text: '설비 가동율(%)'
          },
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            stacked: false,
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0]
          },
          stroke: {
            width: [0, 4]
          },
          xaxis: {
            categories: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
          },
          yaxis: [
            {
              max: 100,
              min: 0,
              title: {
                text: '전체 가동율(%)',
              },
            }, 
            // {
            //   opposite: true,
            //   title: {
            //     text: '목표 가동율'
            //   }
            // }
          ],
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            },
          },
        },
        series: [
          {
            name: '전체 가동율(%)',
            type: 'column',
            data: []
          },
          {
            name: '목표 가동율(%)',
            type: 'line',
            data: []
          },
        ],
        chartWidth: '80%',
      },
      pmRateChart: {
        chartOptions: {
          title: {
            text: 'PM 수행율(%) [실시PM건수/계획PM건수*100%]'
          },
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            stacked: false,
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0]
          },
          stroke: {
            width: [0, 4]
          },
          xaxis: {
            categories: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
          },
          yaxis: [
            {
              max: 100,
              min: 0,
              title: {
                text: '전체 수행율(%)',
              },
            }, 
            // {
            //   opposite: true,
            //   title: {
            //     text: '목표 가동율'
            //   }
            // }
          ],
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            },
          },
        },
        series: [
          {
            name: '전체 수행율(%)',
            type: 'column',
            data: []
          },
          {
            name: '목표 수행율(%)',
            type: 'line',
            data: []
          },
        ],
        chartWidth: '80%',
      },
      period: [],
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // 년도 설정
      this.listUrl1 = selectConfig.kpi.strengthBreakRate.url;
      this.listUrl2 = selectConfig.kpi.frequencyBreakRate.url;
      this.listUrl3 = selectConfig.kpi.operationRate.url;
      this.listUrl4 = selectConfig.kpi.pmRate.url;
      this.targetUrl = selectConfig.kpi.target.year.url;


      // this.searchParam.year = this.$comm.getThisYear();
      this.searchParam.year = '2023';

      this.getList();
    },
    getList() {
      if (!this.searchParam.year) {
        this.grid.data = [];
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '목표년도 선택하세요.', // 작업기간을 선택하세요
          type: 'warning', // success / info / warning / error
        });
        return;
      } else {
        this.getList1();
        this.getList2();
        this.getList3();
        this.getList4();
      }
    },
    getList1() {
      this.$http.url = this.listUrl1;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.strengthFailChart.chartOptions.xaxis.categories = []
        this.strengthFailChart.series[0].data = [];
        this.strengthFailChart.series[1].data = [];
        if (_result.data && _result.data.length > 0) {
          this.strengthFailChart.chartOptions.xaxis.categories = this.$_.map(_result.data, 'mon')
          this.$set(this.strengthFailChart.series[0], 'data', this.$_.map(_result.data, 'mon_value'))

        }
        this.setTarget1();
      },);
    },
    setTarget1() {
      this.$http.url = this.targetUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        year: this.searchParam.year,
        kpiTypeCd: 'KTC0000001',
      };
      this.$http.request((_result) => {
        if (_result.data) {
          for (let i=1; i <= this.strengthFailChart.series[0].data.length; i++) {
            this.strengthFailChart.series[1].data.push(_result.data[0].kpiTarget)
          }
        }
        this.$refs['strengthFailChart'].refresh();
        this.strengthFailChart.chartWidth = '90%';
      },);
    },
    getList2() {
      this.$http.url = this.listUrl2;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
      this.frequencyFailChart.chartOptions.xaxis.categories = []
      this.frequencyFailChart.series[0].data = [];
      this.frequencyFailChart.series[1].data = [];
      if (_result.data && _result.data.length > 0) {
        this.frequencyFailChart.chartOptions.xaxis.categories = this.$_.map(_result.data, 'mon')
        this.$set(this.frequencyFailChart.series[0], 'data', this.$_.map(_result.data, 'mon_value'))
      }
      this.setTarget2();
    },);
    },
    setTarget2() {
      this.$http.url = this.targetUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        year: this.searchParam.year,
        kpiTypeCd: 'KTC0000002',
      };
      this.$http.request((_result) => {
        if (_result.data) {
          for (let i=1; i <= this.frequencyFailChart.series[0].data.length; i++) {
            this.frequencyFailChart.series[1].data.push(_result.data[0].kpiTarget)
          }
        }
        this.$refs['frequencyFailChart'].refresh();
        this.frequencyFailChart.chartWidth = '90%';
      },);
    },
    getList3() {
      this.$http.url = this.listUrl3;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.operationChart.series[0].data = [];
        this.operationChart.series[1].data = [];
        if (_result.data && _result.data.length > 0) {
          this.$set(this.operationChart.series[0], 'data', [_result.data[0]['mon1'], _result.data[0]['mon2']
          , _result.data[0]['mon3'], _result.data[0]['mon4'], _result.data[0]['mon5'], _result.data[0]['mon6']
          , _result.data[0]['mon7'], _result.data[0]['mon8'], _result.data[0]['mon9'], _result.data[0]['mon10']
          , _result.data[0]['mon11'], _result.data[0]['mon12']])
        }
        this.setTarget3();
      },);
    },
    setTarget3() {
      this.$http.url = this.targetUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        year: this.searchParam.year,
        kpiTypeCd: 'KTC0000003',
      };
      this.$http.request((_result) => {
        if (_result.data) {
          this.$set(this.operationChart.series[1], 'data', [_result.data[0].kpiTarget,_result.data[0].kpiTarget
          ,_result.data[0].kpiTarget,_result.data[0].kpiTarget,_result.data[0].kpiTarget,_result.data[0].kpiTarget
          ,_result.data[0].kpiTarget,_result.data[0].kpiTarget,_result.data[0].kpiTarget,_result.data[0].kpiTarget
          ,_result.data[0].kpiTarget,_result.data[0].kpiTarget])
        }
        this.$refs['operationChart'].refresh();
        this.operationChart.chartWidth = '90%';
      },);
    },
    getList4() {
      this.$http.url = this.listUrl4;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.pmRateChart.series[0].data = [];
        this.pmRateChart.series[1].data = [];
        if (_result.data && _result.data.length > 0) {
          this.$set(this.pmRateChart.series[0], 'data', [_result.data[0]['mon1'], _result.data[0]['mon2']
          , _result.data[0]['mon3'], _result.data[0]['mon4'], _result.data[0]['mon5'], _result.data[0]['mon6']
          , _result.data[0]['mon7'], _result.data[0]['mon8'], _result.data[0]['mon9'], _result.data[0]['mon10']
          , _result.data[0]['mon11'], _result.data[0]['mon12']])
        }
        this.setTarget4();
      },);
    },
    setTarget4() {
      this.$http.url = this.targetUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        year: this.searchParam.year,
        kpiTypeCd: 'KTC0000004',
      };
      this.$http.request((_result) => {
        if (_result.data) {
          this.$set(this.pmRateChart.series[1], 'data', [_result.data[0].kpiTarget,_result.data[0].kpiTarget
          ,_result.data[0].kpiTarget,_result.data[0].kpiTarget,_result.data[0].kpiTarget,_result.data[0].kpiTarget
          ,_result.data[0].kpiTarget,_result.data[0].kpiTarget,_result.data[0].kpiTarget,_result.data[0].kpiTarget
          ,_result.data[0].kpiTarget,_result.data[0].kpiTarget])
        }
        this.$refs['pmRateChart'].refresh();
        this.pmRateChart.chartWidth = '90%';
      },);
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  padding: 0px !important
.widget-operationChart
  padding: 5px !important
.widget-operationChart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>